import React from 'react'

const Container1 = ({
    children, 
    index, 
    header1, 
    header2,
    flexDirection,
    className,
    style, 
}) => {
  return (
    <div className={className} style={style}>
        <div 
            className={`flex ${flexDirection || 'flex-col'} gap-10`}
            style={{
                marginBottom : '1rem'
            }}
        >
            <div 
                className='flex flex-col'
                style={{
                    width : 'fit-content'
                }}
            >
                {
                    index && 
                    <p 
                        style={{
                            alignSelf : 'end',
                            whiteSpace : 'nowrap'
                        }}
                    >{`(${index || 1})`}</p>
                }
                {
                    header1 && 
                    <p className='text-3xl bold-xl nowrap text-white'>
                        {
                            header1
                        }
                    </p>
                }
            </div>
            {
                header2 && 
                <p 
                    className='text-md bold-lg text-white'
                >
                    {
                        header2 || 'Construction'
                    }
                </p>
            }
        </div>
        {
            children
        }
    </div>
  )
}

export default Container1
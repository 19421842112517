import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import Auth from "../Auth/Auth";
import "./Navbar.scss";

import logoImg from "../../Assets/Images/navbar/new_logo.svg";
import searchIcon from "../../Assets/Images/navbar/search-icon.svg";

import { toast } from "react-toastify";
// import useOutsideAlerter from '../../Hooks/useOutsideAlerter'
import { debounce, formatName } from "../../Utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { cartAPI, authAPI } from "../../Api";

import Modal from "../Modal2/Modal.js";
import { FaHamburger, FaRegUser, FaSearch } from "react-icons/fa";
import { CiLogout, CiSearch, CiShoppingCart, CiUser } from "react-icons/ci";
import { actionChannel } from "redux-saga/effects";
import { RxHamburgerMenu } from "react-icons/rx";
import { FooterSocialMediaLink } from "../Footer/Footer";
import Button from "../Button/Button";

const mapStateToProps = ({ cart, auth, payment }) => ({
  cart,
  auth,
  payment,
});

const Navbar = ({ data }) => {
  const { cart, auth, payment } = useSelector(mapStateToProps);
  const {
    showLoginPopup = false,
    isLoggedIn,
    userDetails = {},
    tenantDetails = {},
  } = auth;

  const { title, logo } = tenantDetails;

  const path = useLocation().pathname;

  // useEffect(() => {
  //   if (showLoginPopup === true) {
  //     document.querySelector("body").style.overflow = "hidden";
  //   } else {
  //     document.querySelector("body").style.overflow = "auto";
  //   }
  // }, [showLoginPopup]);

  const { walletBalance = 0, isWallet = false } = payment;

  const [searchterm, setsearchterm] = useState(""); //text within searchbox
  const [showsearch, setshowsearch] = useState("");
  const [walletData, setWalletData] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const dispatch = useDispatch();
  const [company, setCompany] = useState([]); //company Details

  // const debounce = (fn, delay) => {   //debounce for text search
  //     let timeoutID;
  //     return function (...args) {

  //         if (timeoutID) {
  //             clearTimeout(timeoutID)
  //         }
  //         timeoutID = setTimeout(() => {
  //             fn(...args)
  //         }, delay)
  //     }
  // }

  useEffect(() => {
    // props.search(searchterm);
  }, [searchterm]);

  const logOut = () => {
    dispatch(actionsCreator.LOGOUT_USER());
    toast.error("User is logged out successfully");
    setOpenProfile(false);
  };

  const walletFetcher = async () => {
    try {
      let res = await cartAPI.getWalletData();
      dispatch(
        actionsCreator.UPDATE_WALLET({
          walletBalance: res.data.data.balance,
          isWallet: true,
        })
      );
    } catch (error) {
      dispatch(actionsCreator.UPDATE_WALLET({ isWallet: false }));
    }
  };

  // const companyFetcher = async () => {
  //   try {
  //     let res = await authAPI.company();
  //     setCompany(res.data);
  //   } catch (error) {
  //     console.log("ERROR in Company API", error)
  //   }
  // };

  useEffect(() => {
    if (isLoggedIn) walletFetcher();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   companyFetcher();
  // }, [])

  const { name = "", email = "" } = userDetails || {};
  const formattedName = formatName(name);
  const { final_item = 0, final_price = 0 } = cart || {};

  function useOutsideAlerter(ref, key, loginRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        // if (ref.current && !ref.current.contains(event.target)) {
        //   if (
        //     key === "login" &&
        //     loginRef.current &&
        //     !loginRef.current.contains(event.target)
        //   )
        //     props.removeLoginPopup();
        //   else setOpenProfile(false);
        // }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const loginStateSetter = () => {
    dispatch(actionsCreator.SHOW_LOGIN());
  };

  const clickOutsideProfile = useRef();
  const clickOutsideLogin = useRef();
  const loginMenuBtn = useRef();

  useOutsideAlerter(clickOutsideProfile, "profile");
  useOutsideAlerter(clickOutsideLogin, "login", loginMenuBtn);

  const [showHamburgerNav, setShowHamburgerNav] = useState(false);
  const handleModalDisplay = () => {
    setShowHamburgerNav((prev) => !prev);
  };

  useEffect(() => {
    setShowLoginModal(showLoginPopup);
  }, [showLoginPopup]);

  const [showloginModal, setShowLoginModal] = useState(showLoginPopup);

  const removeLoginPopup = () => {
    dispatch(actionsCreator.SET_LOGIN({ showLoginPopup: false }));
    setShowLoginModal(false);
    // setsearchResults([]);
  };

  const [displaySearch, toggleShowSearch] = useState(false);

  return (
    <nav
      className="navbar w-full flex items-center"
      style={{
        position: path === "/" ? "absolute" : "relative",
        background: path === "/" ? "transparent" : "white",
      }}
    >
      {showHamburgerNav && (
        <Modal
          closeModal={handleModalDisplay}
          backgroundColor={"#00000080"}
          style={{
            width: "100%",
          }}
        >
          <ul
            className="h-full w-full ul-style-none flex flex-col items-center text-white gap-20"
            style={{
              marginTop: "6rem",
            }}
          >
            <li
              onClick={() => {
                setShowHamburgerNav(false);
              }}
              style={{
                borderBottom: path == "/" && "1px solid white",
                width: "80%",
                paddingBottom: "1rem",
                textAlign: "center",
              }}
            >
              <Link to="/" className="text-white text-large">
                Home
              </Link>
            </li>
            <li
              onClick={() => {
                setShowHamburgerNav(false);
              }}
              style={{
                borderBottom: path == "/privacy-policy" && "1px solid white",
                width: "80%",
                paddingBottom: "1rem",
                textAlign: "center",
              }}
            >
              <Link
                to="/privacy-policy#ContactUs"
                className="text-white text-large"
              >
                Contact Us
              </Link>
            </li>
            {/* <li> */}
            <Button
              active={true}
              onClick={() => {
                isLoggedIn ? logOut() : setShowLoginModal(true);
              }}
            >
              {!isLoggedIn ? "Log in" : "Log Out"}
            </Button>
            {/* </li> */}
          </ul>
        </Modal>
      )}

      <div className="navbar-items w-full text-white">
        <div className="flex flex-row items-center gap-10">
          {

            logo &&
            <img
              src={logo}
              alt={'Logo'}
              style={{
                height : '3rem',
                aspectRatio : '1',
                objectFit : 'contain',
                borderRadius : '10px',
              }}
            />
          }

          <Link to="/" className={`text-2xl text-uppercase ${path === "/" ? "text-white" : "text-black"} bold-lg`}>
            {title || "Camping"}
          </Link>
        </div>

        {/* <div 
          className="absolute block-lg-only flex-row gap-15 p-3 radius-secondary border-first"
          style={{
            width : '33%',
            left : '50%',
            transform : 'translateX(-50%)'
          }}
        >
          <CiSearch size={'1.5rem'} color={'#30363C'} />
          <input 
            type={'search'}
            placeholder="Seach here..."
            className="w-full text-large"
            onChange={debounce((event) => {
              props.search(event.target.value);
            }, 600)}
          />
        </div> */}

        <div className="flex flex-row items-center gap-20">
          <ul className="h-full w-full ul-style-none flex-row items-center text-white gap-20 flex-lg-only">
            <li
              onClick={() => {
                setShowHamburgerNav(false);
              }}
              style={{
                borderBottom: path == "/" && "1px solid white",
              }}
            >
              <Link to="/" className={`${path === "/" ? "text-white" : "text-black"} text-md`}>
                Home
              </Link>
            </li>
            <li
              onClick={() => {
                setShowHamburgerNav(false);
              }}
              style={{
                borderBottom: path == "/privacy-policy" && "1px solid white",
              }}
            >
              <Link
                to="/privacy-policy#ContactUs"
                className={`${path === "/" ? "text-white" : "text-black"} text-md`}
              >
                Contact Us
              </Link>
            </li>
            {/* <li> */}
            <button
              onClick={() => {
                isLoggedIn ? logOut() : setShowLoginModal(true);
              }}
              className={`${path === "/" ? "text-white" : "text-black"} text-md btn-none`}
            >
              {!isLoggedIn ? "Log in" : "Log Out"}
            </button>
          </ul>

          <button className="relative">
            <Link to="/checkout" className={`${path === "/" ? "text-white" : "text-black"} flex items-center gap-5`}>
              <div
                className="absolute text-black text-md bold-md"
                style={{
                  top: "-20%",
                  left: "-10%",
                }}
              >
                {final_item !== 0 && (
                  <span className={`${path === "/" ? "text-white" : "text-black"} text-md bold-md`}>
                    {final_item}
                  </span>
                )}
              </div>
              <CiShoppingCart size={"1.3rem"} color={path === "/" ? "white" : "black"} />
            </Link>
          </button>

          <button
            onClick={() => {
              setShowHamburgerNav(true);
            }}
            className="hamburger"
          >
            <RxHamburgerMenu
              fontSize={"1.3rem"}
              color={path === "/" ? "white" : "black"}
            />
          </button>

          {
            // true ?
            // <button
            //   // onClick={() => {
            //   //   dispatch(actionsCreator.SHOW_LOGIN())
            //   // }}
            //   className={'block-lg-only flex item-center gap-5'}
            // >
            //   <FaRegUser size={'1rem'} color={'black'}/>
            //   <span className="text-black text-md bold-md block-lg-only">Hello, Log In</span>
            // </button> :
            // <button
            //   onClick={logOut}
            //   className={'block-lg-only items-center flex gap-5'}
            // >
            //   <CiLogout size={'1.2rem'} color={'black'}/>
            //   <span className="text-first text-large block-lg-only medium-bold">Log Out</span>
            // </button>
          }
        </div>

        {showloginModal && (
          <Modal closeModal={removeLoginPopup}>
            <div className="h-full w-full flex flex-col justify-center items-center">
              <Auth />
            </div>
          </Modal>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

Navbar.defaultProps = {
  location: "Whitefield",
  total_cost: 0,
};

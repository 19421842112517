import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  Categories,
  Foryou,
  Navbar,
  Offers,
  Help,
  Bottom,
  Install,
  WhyShop,
  Footer,
  Login,
  Overlay,
  Modal,
  Navigation,
  SearchResults,
  ProductCard,
  AddToCartButton,
} from "../../Components";

import { actionsCreator } from "../../Redux/actions/actionsCreator";
import giftIcon from "../../Assets/Images/gifs/gift.gif";
import medkit from "../../Assets/Images/medkit.png";
import girlwithmeds from "../../Assets/Images/girlwithmeds.png";
import bluebg from "../../Assets/Images/bluebg.png";

import tablets from "../../Assets/Images/tablets.png";
import playVideo from "../../Assets/Images/play-video.png";
import doctorVideo from "../../Assets/Images/doctor-video.png";

import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaPlayCircle,
} from "react-icons/fa";
import "./Home.scss";
import { welcomeModalHandler } from "../../Utils";
import Bannerv2 from "../../Components/Homepage/Bannerv2";
import Slideshow from "../../Components/Homepage/Bannerv2/Slideshow";
import Dashboard from "../dashboard";
import ProductsSlider from "../../Components/ProductCategory/Products";
import Container1 from "../../Components/Container/Container1";
import { CiSearch, CiUser } from "react-icons/ci";
import Caraousel from "../../Components/Caraousel/Caraousel";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";
import Home2 from "../../Assets/Images/Home2.png";
import Home3 from "../../Assets/Images/Home3.png";

import Bus1 from "../../Assets/Images/Bus.svg";
import Bus2 from "../../Assets/Images/bus2.svg";
import Tent from "../../Assets/Images/tent.svg";
import Tent2 from "../../Assets/Images/hometent.svg";
import Tent3 from "../../Assets/Images/hometent2.svg";

import BannerImg from "../../Assets/Images/BannerImg.svg";
import BannerImg2 from "../../Assets/Images/bannerbg-2.svg";
import PeopleGroup from "../../Assets/Images/peoplegroup.svg";

import wildlife from "../../Assets/Images/wildlife.svg";
import bonfire from "../../Assets/Images/bonfire.svg";
import farming from "../../Assets/Images/farming.svg";
import hiking from "../../Assets/Images/hiking.svg";

import { CiCalendarDate } from "react-icons/ci";
import Button from "../../Components/Button/Button";
import { fetchAvailableProd, getAllFeaturedProd } from "../../Api/travelApi";
import { toast } from "react-toastify";

const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

export const BannerComponent = ({ data, categoryList }) => {
  const banners = data.images ? data.images : [{}, {}, {}, {}];

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [noOfGuest, setNoOfGuest] = useState(1);
  const [accomodation, setAccomodation] = useState(1);

  const history = useHistory();

  const sliderRef = useRef(null);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    setEndDate(formattedDate);
    setStartDay(daysOfWeek[today.getDay()]);
    setEndDay(daysOfWeek[today.getDay()]);

    const autoScroll = setInterval(() => {
      if (sliderRef.current) {
        const scrollLeft = sliderRef.current.scrollLeft;
        const offSetWidth = sliderRef.current.offsetWidth;
    
        const newScrollPosition = scrollLeft + offSetWidth;
    
        if (newScrollPosition >= sliderRef.current.scrollWidth) {
          sliderRef.current.scrollLeft = 0;
        } else {
          sliderRef.current.scrollLeft = newScrollPosition;
        }
      }
    }, 3000);

    return () => {
      clearInterval(autoScroll);
    };
  }, []);

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;
    const dateObject = new Date(selectedDate);
    const dayOfWeek = dateObject.getDay();
    if (type == "start") {
      setStartDate(selectedDate);
      setStartDay(daysOfWeek[dayOfWeek]);
    } else {
      setEndDate(selectedDate);
      setEndDay(daysOfWeek[dayOfWeek]);
    }
  };

  const handleFetchAvailability = () => {
    history.push(`/category-availabile/${accomodation}`, {
      catId: accomodation,
      checkin: startDate,
      checkout: endDate,
    });
  };

  return (
    <div
      className="nowrap overflow-x-scroll w-full h-full scrollbar-hidden"
      style={{
        scrollSnapType: "x",
        scrollBehavior: "smooth",
      }}
      id={"checkavailability"}
      ref={sliderRef}
    >
      {banners.map((banner, index) => {
        return (
          <div
            className="repsonsive-flex-row items-center justify-center relative"
            style={{
              backgroundImage: `url(${banner?.uri || BannerImg})`,
              height: "95vh",
              width: "100%",
              backgroundPosition: "center",
              objectFit: "contain",
              backgroundRepeat: "no-repeat",
              display: "inline-flex",
              scrollSnapAlign: "center",
              scrollSnapStop: "always",
              overflow : 'hidden'
            }}
          >
            <div className="w-full space-y-10 p-10">
              <p className="text-xl text-white">
                {banner?.text || "Let's make S'more memories"}
              </p>
              <p className="text-3xl bold-xl text-white wrap">
                {banner?.description ||
                  "Camp is more than Just a word, It's an Experience"}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const AboutUsComponent = () => {
  const {
    auth,
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  return (
    <div className="space-y-10 flex flex-col items-center about-us-camp">
      <div className="text-center w-full flex flex-col items-center gap-15">
        <p className="text-black text-3xl bold-xl">
          Welcome To {auth?.tenantDetails?.title || "JJCamping"}
        </p>
        <p
          className="text-black text-center"
          style={{
            width: "80%",
          }}
        >
         Step into the sanctuary of the farm and feel the embrace of nature&apos;s bounty. Each moment spent here is a journey of rejuvenation for the mind, body, and soul. Listen closely as the wind whispers its secrets, breathe in the freshness of the rain-kissed air, and delight your senses with the vibrant hues of nature&apos;s canvas.        </p>
      </div>

      <ul
        className="flex responsive-flex-row justify-around w-full ul-style-none gap-20"
        style={{
          paddingTop: "5rem",
        }}
      >
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Bus1} />
          <span>Parking Available</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Tent} />
          <span>CAMPING SITES</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Tent2} />
          <span>A STRUCTURE CABINS</span>
        </li>
        <li className="text-center flex flex-col items-center gap-10">
          <img src={Tent3} />
          <span>CABIN SITES</span>
        </li>
      </ul>
    </div>
  );
};

export const CategoryComponent = ({ categoryList }) => {
  const history = useHistory();

  return (
    <div
      id="category-component"
      className="flex responsive-flex-row w-full category-wrapper-jjcamping"
      style={{
        overflowX: "hidden",
      }}
    >
      <Caraousel>
        {categoryList.map((item) => {
          return (
            <button
              className="flex-col btn-none space-y-10"
              style={{
                width: "20rem",
                display: "inline-flex",
                marginRight: "1%",
              }}
              // onClick={() => {
              //   history.push(`/categories/${item.name}`);
              // }}
            >
              <img
                src={item.image || ""}
                className=""
                style={{
                  height: "18rem",
                  aspectRatio: "1",
                  objectFit: "contain",
                }}
              />
              <span className="text-center text-active-camp text-lg bold-lg w-full">
                {item.name || "Name"}
              </span>
              <span
                className="text-center overflow-ellipsis w-full text-md"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {item?.description?.substring(0, 50) ||
                  "adlf adlf alskdfjlaksdjf akjf sadkjf ksdjf dksjf fkds jfkja fkjas dfjka dfjkas djfkajsdfka dfkjas dfkjaksdf jkasd fjka dfjka f".substring(
                    0,
                    50
                  )}
              </span>
            </button>
          );
        })}
      </Caraousel>
    </div>
  );
};

export const ActivityComponent = () => {
  const activityData = [
    {
      url: wildlife,
      title: "Wild Life",
      desc: "Embark on a wildlife adventure with us. From safaris to birdwatching, discover the wonders of nature firsthand.",
    },
    {
      url: bonfire,
      title: "Bonfire",
      desc: "Gather around the warmth of a bonfire with us. Share stories, laughter, and marshmallows under the starry night sky. Join the fun!",
    },
    {
      url: farming,
      title: "Farming",
      desc: "Experience the joys of farming with us. Get your hands dirty, harvest fresh produce, and reconnect with nature's rhythms.",
    },
    {
      url: hiking,
      title: "Hiking",
      desc: "Embark on a hiking adventure with us. Explore scenic trails, breathe in the crisp mountain air, and discover hidden wonders along the way.",
    },
  ];

  return (
    <div className="flex responsive-flex-row gap-20 featured-product-list cards-margin">
      <div className="space-y-10 flex-1">
        <p className="text-active-camp">Activity</p>
        <p className="text-3xl bold-xl">
          Camp Will Be For You What You Want It To Be.
        </p>
        <img
          src={PeopleGroup}
          style={{
            marginTop: "2rem",
            height: "80%",
            aspectRatio: "1",
            objectFit: "contain",
          }}
        />
      </div>
      <div className="responsive-cards-2-1 flex-1">
        {activityData.map((item) => {
          return (
            <div className="space-y-10">
              <img
                src={item.url}
                style={{
                  height: "10rem",
                  aspectRatio: "1",
                  objectFit: "contain",
                }}
              />
              <p className="text-lg bold-lg">{item.title}</p>
              <p className="text-inactive text-sm">{item.desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ProductCardCamp = ({ data, details }) => {
  const [info, setInfo] = useState({
    checkin: details?.checkin,
    checkout: details?.checkout,
    noofguest: details?.noofguest,
    id: data?.id,
  });

  const [modal, toggleModal] = useState(false);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [noOfGuest, setNoOfGuest] = useState(1);
  const [accomodation, setAccomodation] = useState(1);

  const history = useHistory();

  useEffect(() => {
    // Set today's date as the default value
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setStartDate(formattedDate);
    setEndDate(formattedDate);
    setStartDay(daysOfWeek[today.getDay()]);
    setEndDay(daysOfWeek[today.getDay()]);
  }, []);

  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;
    const dateObject = new Date(selectedDate);
    const dayOfWeek = dateObject.getDay();
    if (type == "start") {
      setStartDate(selectedDate);
      setStartDay(daysOfWeek[dayOfWeek]);
    } else {
      setEndDate(selectedDate);
      setEndDay(daysOfWeek[dayOfWeek]);
    }
  };

  const [loading, setLoading] = useState(false);

  const [available, setAvailable] = useState(false);
  const handleFetchAvailability = async () => {
    setLoading(true);

    try {
      const response = await fetchAvailableProd({
        prodId: data?.id,
        params: {
          checkin: startDate,
          checkout: endDate,
          noofguest: noOfGuest,
        },
      });

      if (response.data) {
        setAvailable(response.data.message);
      }
    } catch (err) {
      toast.error("Error in checking availability");
    }

    setLoading(false);
  };

  return (
    <>
      <Modal
        show={modal}
        onClose={() => {
          toggleModal(false);
        }}
      >
        <ul
          className="flex flex-col gap-20 ul-style-none round-10"
          style={{
            paddingTop: "5rem",
          }}
        >
          <li
            className="flex-1 flex responsive-flex-row gap-20 w-full"
            style={{
              flexGrow: "2",
            }}
          >
            <div className="flex-1">
              <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
                <CiCalendarDate />
                Check In
              </span>
              {/* <input type={"date"} /> */}

              <div className="flex flex-row nowrap items-center">
                <span className="text-md bold-md">
                  {(startDay || "") + ","}
                </span>
                <input
                  type="date"
                  value={startDate}
                  onChange={(event) => {
                    handleDateChange(event, "start");
                  }}
                  className="text-md bold-md w-full flex-1"
                  style={{
                    padding: "5px",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </div>
            <div className="flex-1">
              <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
                <CiCalendarDate />
                Check Out
              </span>
              <div className="flex flex-row nowrap items-center">
                <span className="text-md bold-md">{(endDay || "") + ","}</span>
                <input
                  type="date"
                  value={endDate}
                  onChange={(event) => {
                    handleDateChange(event, "end");
                  }}
                  className="text-md bold-md w-full flex-1"
                  style={{
                    padding: "5px",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </div>
          </li>

          <li className="flex-1 w-full">
            <span className="text-uppercase text-md text-active-camp flex flex-row items-center gap-5">
              <CiUser />
              Guests
            </span>
            <select
              value={noOfGuest}
              className="w-full text-md bold-md"
              style={{
                outline: "none",
                border: "none",
              }}
              onChange={(event) => {
                setNoOfGuest(event.target.value);
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
                return (
                  <option value={item}>
                    {item + " Adult" + (item > 1 ? "s" : "")}
                  </option>
                );
              })}
            </select>
          </li>
          <li className="flex-1 w-full flex flex-row justify-between gap-20">
            <Button
              active={false}
              onClick={() => {
                toggleModal(false);
              }}
              className="text-black flex-1"
            >
              Cancel
            </Button>

            <Button
              active={true}
              className="flex-1"
              loading={loading}
              onClick={handleFetchAvailability}
            >
              Check Availability
            </Button>
          </li>

          {available && (
            <>
              <hr
                style={{
                  marginTop: "1rem",
                }}
              />
              <li className="text-md bold-md text-center flex flex-col justify-center space-y-10">
                {available || "Room is available"}
                <AddToCartButton product={data}>Book Now</AddToCartButton>
              </li>
            </>
          )}
        </ul>
      </Modal>

      <div
        className="flex flex-col gap-15"
        style={{
          marginRight: "1%",
        }}
      >
        <img
          src={data.photo || ""}
          className="w-full"
          style={{
            height: "24rem",
            objectFit: "contain",
          }}
        />
        <span className="text-active-camp text-lg bold-lg w-full">
          {data.product_name || "Tent 1"}
        </span>
        <span
          className="overflow-ellipsis w-full text-md"
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {data?.description?.substring(0, 50) ||
            "adlf adlf alskdfjlaksdjf akjf sadkjf ksdjf dksjf fkds jfkja fkjas dfjka dfjkas djfkajsdfka dfkjas dfkjaksdf jkasd fjka dfjka f".substring(
              0,
              50
            )}
        </span>
        <div>
          {!details ? (
            <Button
              active={true}
              onClick={() => {
                toggleModal(true);
              }}
            >
              Check Availability
            </Button>
          ) : (
            <AddToCartButton product={data}>Book Now</AddToCartButton>
          )}
        </div>
      </div>
    </>
  );
};

export const HomePageProduct = ({ featuredProd = [] }) => {
  const history = useHistory();

  return (
    <div className="space-y-10 flex flex-col items-center featured-product-list cards-margin">
      <div className="text-center w-full flex flex-col items-center gap-15">
        <p className="text-active-camp">Booking</p>
        <p className="text-black text-3xl bold-xl">
          Book your dream <br /> Vacation now
        </p>
      </div>

      <div
        className="responsive-cards-2-1 w-full gap-20"
        style={{
          paddingTop: "2rem",
        }}
      >
        {featuredProd.map((item) => {
          return <ProductCardCamp data={item} />;
        })}
      </div>
    </div>
  );
};

export const OurGallery = () => {
  
  const [activeIndex, toggleActiveIndex] = useState(0)


//  console.log("activeIndex", activeIndex)

  const galleryData = [
    {
      id: "data1",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/PXL_20221122_164439585.NIGHT.jpg",
      data: "Immerse yourself in the magic of the night sky with us. Join our stargazing sessions and marvel at the beauty of the cosmos. Discover constellations, planets, and galaxies in a breathtaking celestial journey. Join us under the stars!",
    },
    {
      id: "data2",
      uri: "https://aiktech.s3.amazonaws.com/phurti-cloudfront/products/dji_fly_20221121_173928_686_1669033234337_photo_optimized.jpg",
      data: "Behold our resort, a tranquil oasis nestled amidst nature's embrace. With lush gardens, sparkling pools, and elegant architecture, every corner exudes serenity and luxury. Join us and indulge in a paradise of relaxation and rejuvenation.",
    }
  ];

  return (
    <div className="flex responsive-flex-row gap-10 featured-product-list cards-margin">
      <div 
        className="space-y-10 gallery-text-container"
      >
        <p className="text-3xl bold-xl">Resort Retreat</p>
        {galleryData.map((item) => {
           return (galleryData[activeIndex].id == item.id) && (
            <p className="text-md">
              {
                item.data
              }
            </p>
          );
        })}
      </div>

      <Caraousel
        leftClick = {
          () => {
            toggleActiveIndex(prev => {
              if(prev > 0){
                return prev - 1;
              }else{
                return 0;
              }
            })
          }
        }

        rightClick = {
          () => {
            toggleActiveIndex(prev => {
              if(prev >= galleryData.length - 1){
                return prev;
              }else{
                return prev + 1;
              }
            })
          }
        }
      >
        {galleryData.map((item) => {
          return (
            <img
              src={item.uri}
              style={{
                width: "100%",
                height: "80vh",
                objectFit: "contain",
                scrollSnapAlign : 'center'
              }}
            />
          );
        })}
      </Caraousel>
    </div>
  );
};

export const WhyUsComponent = ({ data }) => {
  return (
    <div className="p-2">
      <Container1
        index={"3"}
        header1={"Designed For You"}
        header2={
          "We've designed and curated pieces that are a cut above your average home goods, because when you level up your everyday objects, you elevate your daily rituals. Let the magic happen."
        }
        flexDirection={"responsive-flex-row items-center gap-10"}
        className="gap-20 flex flex-col"
      >
        <div className="flex responsive-flex-row gap-20">
          <img
            src={Home2}
            className="flex-1 whyus-comp-firstimg"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
          <img
            src={Home3}
            className="flex-1"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
        </div>
        <div className="flex responsive-flex-row gap-20">
          <img
            src={Home2}
            className="flex-1"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
          <img
            src={Home3}
            className="flex-1 whyus-comp-firstimg"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
        </div>
      </Container1>
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth,
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  const { isLoggedIn, tenantDetails = {}, template_configs = [] } = auth;

  const [home, setHome] = useState({});

  const [offers, setOffers] = useState(true);
  const [subcategory, setSubcategory] = useState([]);

  const subcategoryAdd = () => {
    let temp_sub_category = [];
    categoryList.map((item) => {
      item.sub_categories &&
        item.sub_categories.map((subitem) => {
          if (subitem.home_page) {
            temp_sub_category.push(subitem);
          }
        });
    });
    setSubcategory(temp_sub_category);
  };

  useEffect(() => {
    subcategoryAdd();
  }, [categoryList]);

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  // const [modal, setModal] = useState(true);
  const banner = tenantDetails?.template_configs
    ? tenantDetails.template_configs[0]?.config?.home.banner
    : {};
  useEffect(() => {
    if (tenantDetails.template_configs) {
      const { home } = tenantDetails?.template_configs[0]?.config;

      setHome(home);
    }

    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }

    setActiveCategory(categoryList[0]);
  }, [auth]);

  const [featuredProd, setFeaturedProd] = useState([]);

  useEffect(() => {
    const getFeaturedProd = async () => {
      try {
        const response = await getAllFeaturedProd();
        if (response.data) {
          const prodList = response.data.featured_products;
          const featList = prodList.map((item) => {
            return item.product;
          });
          setFeaturedProd(featList);
        }
      } catch (err) {
        // toast.error("Error in fetching featuredProducts");
        console.log("featured Prod", err);
      }
    };

    getFeaturedProd();
  }, []);

  const [activeCategory, setActiveCategory] = useState({});

  const [query, setQuery] = useState("");
  const debouncedSearch = useDebounce(query, 500);

  return (
    <div
      className="home"
      style={{
        background: "#F7F2DB",
      }}
    >
      <BannerComponent data={banner} categoryList={categoryList} />

      {/* {
        home?.categories && 
        categoryList.length > 0
        && 
        <CategoryComponent 
          categoryList={categoryList} 
        />
      } */}

      <AboutUsComponent />

      {home.categories && categoryList.length > 0 && (
        <CategoryComponent categoryList={categoryList} />
      )}

      <ActivityComponent />

      <div
        className="w-full flex flex-row items-center justify-center"
        style={{
          height: "80vh",
          backgroundImage: `url(${BannerImg2})`,
          backgroundPosition: "center",
          objectFit: "contain",
          backgroundRepeat: "none",
          marginTop: "5rem",
        }}
      >
        <div className="flex flex-col gap-20 p-2">
          <p className="text-3xl text-white">
            A Bonfire Is Basically Just A <br /> Nightclub In The Mountains
          </p>
          {/*<div className="flex flex-row justify-center w-full">
            <Button
              active={false}
              onClick={() => {
                document
                  .getElementById("checkavailability")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Check Availability
            </Button>
          </div>
          */}
        </div>
      </div>

      {home?.offers && <Offers />}

      {/* {
        home?.featured_products 
        && 
        categoryList.length > 0
        &&
        <HomePageCategory
          categoryList={categoryList}
        />
      } */}

      {true && featuredProd.length > 0 && (
        <HomePageProduct featuredProd={featuredProd} />
      )}

      {<OurGallery />}

      {home?.why_us && <WhyUsComponent />}
    </div>
  );
};

export default Home;

{
  /* <ul
        id="checkavailability"
        className="flex responsive-flex-row gap-20 ul-style-none booking-input-form round-10"
        style={{
          backgroundColor: "white",
          width: "80%",
          position: "absolute",
          top: "100%",
          overflow: "hidden",
        }}
      >
        <li
          className="flex-1 flex responsive-flex-row gap-20 w-full"
          style={{
            flexGrow: "2",
          }}
        >
          <div className="flex-1">
            <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
              <CiCalendarDate />
              Check In
            </span>
            {/* <input type={"date"} /> */
}

//     <div className="flex flex-row nowrap items-center">
//       <label htmlFor="datePicker" className="text-md bold-md">
//         {(startDay || "") + ","}
//       </label>
//       <input
//         type="date"
//         id="datePicker"
//         value={startDate}
//         onChange={(event) => {
//           handleDateChange(event, "start");
//         }}
//         className="text-md bold-md w-full flex-1"
//         style={{
//           padding: "5px",
//           border: "none",
//           outline: "none",
//         }}
//       />
//     </div>
//   </div>
//   <div className="flex-1">
//     <span className="text-uppercase text-active-camp text-md flex flex-row items-center gap-5">
//       <CiCalendarDate />
//       Check Out
//     </span>
//     <div className="flex flex-row nowrap items-center">
//       <label htmlFor="datePicker" className="text-md bold-md">
//         {(endDay || "") + ","}
//       </label>
//       <input
//         type="date"
//         id="datePicker"
//         value={endDate}
//         onChange={(event) => {
//           handleDateChange(event, "end");
//         }}
//         className="text-md bold-md w-full flex-1"
//         style={{
//           padding: "5px",
//           border: "none",
//           outline: "none",
//         }}
//       />
//     </div>
//   </div>
// </li>

// <li className="flex-1 w-full">
//   <span className="text-uppercase text-md text-active-camp flex flex-row items-center gap-5">
//     <CiUser />
//     Guests
//   </span>
//   <select
//     value={noOfGuest}
//     className="w-full text-md bold-md"
//     style={{
//       outline: "none",
//       border: "none",
//     }}
//     onChange={(event) => {
//       setNoOfGuest(event.target.value);
//     }}
//   >
//     {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => {
//       return (
//         <option value={item}>
//           {item + " Adult" + (item > 1 ? "s" : "")}
//         </option>
//       );
//     })}
//   </select>
// </li>
// <li className="flex-1 w-full">
//   <span className="text-uppercase text-md text-active-camp flex flex-row items-center gap-5">
//     <CiCalendarDate />
//     Accomodation
//   </span>
//   <select
//     className="w-full text-md bold-md"
//     style={{
//       outline: "none",
//       border: "none",
//     }}
//     onChange={(event) => {
//       setAccomodation(event.target.value);
//     }}
//   >
//     {categoryList.map((item) => {
//       return <option value={item.id}>{item.name}</option>;
//     })}
//   </select>
// </li>
// <li className="flex-1 w-full">
//   <Button
//     className={"w-full"}
//     active={true}
//     onClick={handleFetchAvailability}
//   >
//     Check Availability
//   </Button>
// </li>

// </ul> */}
